import Image from 'next/image'
import { useTranslation } from 'react-i18next'

import { Div } from 'elements/layout/Div'
import { Space } from 'elements/layout/Space'
import { Icon } from 'elements/media/Icon'
import { P } from 'elements/text/P'

import { ICON } from 'config/icons'
import { Locale } from 'config/types'
import { BlockLink } from 'general/structure/Navigation/BlockLink'
import { GuidesLinkGrid } from 'general/structure/Navigation/GuidesLinkGrid'
import { PAGE } from 'routes/constants'

export const Error503Page: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Div p="40px 20px 20px" w="90%" maxW="550px" m="auto">
      <Div align="center" m="0 auto 20px" w="100%">
        <Icon name={ICON.WARNING_SOLID} color={'yellow'} size="35px" />
        <P fontSize={{ xs: '1.4em', sm: '1.6em' }} bold m="0 auto 20px" color={'yellow'} w={'80%'}>
          trans:common:error.503.title
        </P>
        <P m="0 auto 20px" w={{ xs: '80%', lg: '70%' }}>
          trans:common:error.503.message
        </P>
        {language === Locale.fr && (
          <P fontSize={'1em'} bold m="0 auto 0px" w={{ xs: '70%', lg: '80%' }}>
            trans:common:error.503.message2
          </P>
        )}
      </Div>

      {language === Locale.fr && (
        <>
          <BlockLink to="https://qrco.de/bdWZGc" bg="whiteOrange" hoverBg="orange">
            <Image src="/svg/rosario-solid-gradient.svg" width="35px" height="35px" alt="rosario" />
            <P color="yellow">trans:common:rosario.discover</P>
          </BlockLink>

          <br />

          <BlockLink
            to={{ pathname: PAGE.STATIC, query: { pageSlug: 'evenements' } }}
            icon={ICON.EVENT}
            iconColor="blue"
            bg="lightBlue"
            hoverBg="blue"
          >
            <P color="blue">trans:common:event.blocklink.title</P>
          </BlockLink>
        </>
      )}

      <P m="30px auto 20px" bold align={'center'} w="60%">
        trans:common:guide.list.discover.title
      </P>

      <GuidesLinkGrid />

      <Space h="80px" />
    </Div>
  )
}
